"use client";

import {
  FormEventHandler,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// Next
import { useRouter, useSearchParams } from "next/navigation";
import { useTranslations } from "next-intl";

// Cookie
import cookie from "js-cookie";

// Sentry
import * as Sentry from "@sentry/nextjs";

// React Icons
import { HiLogin, HiLogout, HiOutlineKey, HiOutlineUser } from "react-icons/hi";

// React Hook Form
import { SubmitHandler, useForm } from "react-hook-form";

// Zod
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

// Components`
import { Button } from "@/components/common/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/FormElements/Form";
import { Input } from "@/components/FormElements/Input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/common/Tooltip";

// Utils
import { getFQCN } from "@/utils/functions/fqcn";
import { useAppDispatch, useAppSelector } from "@/utils/hooks/store";
import { loginThunk } from "@/store/thunks/authThunk";

const formSchema = z.object({
  username: z.string().min(1, {
    message: "Username must not be empty.",
  }),
  password: z.string().min(1, {
    message: "Password must not be empty.",
  }),
});

const fqcn_bui = {
  Bundle: "securityBundle",
  Unit: "login",
  Interface: "login",
};

type FormValues = {
  username: string;
  password: string;
};

export default function LoginForm({
  params: { locale },
}: {
  params: { locale: string };
}) {
  const t = useTranslations("Login");

  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.auth.login);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const [classes, setClasses] = useState("border-gray-300");
  const router = useRouter();
  const [errorMsg, setErrorMsg] = useState("");

  const searchParams = useSearchParams();

  const handleSubmit: SubmitHandler<FormValues> = async (
    values: FormValues
  ) => {
    try {
      await dispatch(loginThunk(values)).unwrap();

      setClasses("border-gray-400 ring-2 ring-green-500 ring-offset-4");
      setErrorMsg("");

      if (cookie.get("currentUser")) {
        const returnUrl = searchParams?.get("returnUrl")
          ? searchParams?.get("returnUrl")?.replace(/[\[\]]/g, "")
          : locale === "fr"
          ? "/fr/dashboard"
          : "/en/dashboard";

        let returnQuery = "";
        if (window?.location?.search) {
          const match = window.location.search.match(
            /returnQuery=\[([^\]]+)\]/
          );
          if (match) {
            returnQuery = match[1]?.replace(/[\[\]]/g, "");
          }
        }

        if (returnQuery && !returnQuery.startsWith("?")) {
          returnQuery = "?" + returnQuery;
        }

        const redirectUrl = `${returnUrl}${returnQuery}`;
        router.push(redirectUrl);
      }
    } catch (err: any) {
      setClasses("border-gray-400 ring-2 ring-red-500 ring-offset-4");
      setErrorMsg(err?.message);
      Sentry.captureException(err);
    }
  };

  useEffect(() => {
    form.setValue("username", searchParams.get("username") || "");
    form.setValue("password", searchParams.get("password") || "");

    if (searchParams.get("username") || searchParams.get("password")) {
      handleSubmit({
        username: searchParams.get("username") || "",
        password: searchParams.get("password") || "",
      });
    }
  }, []);

  return (
    <>
      <h1
        id={getFQCN(fqcn_bui, "leftside-authform", "login-title")}
        className="text-3xl font-bold text-center"
      >
        {t("Log in")}
      </h1>

      <Form {...form}>
        <form
          id={getFQCN(fqcn_bui, "leftside-authform")}
          onSubmit={form.handleSubmit(handleSubmit)}
          className="space-y-8"
        >
          <FormField
            control={form.control}
            name="username"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("Username")}</FormLabel>
                <FormControl>
                  <div className="relative w-full">
                    <HiOutlineUser
                      id={getFQCN(fqcn_bui, "user-login-icon")}
                      className="absolute right-0 top-0 m-2.5 h-5 w-5"
                    />
                    <Input
                      autoFocus
                      id={getFQCN(
                        fqcn_bui,
                        "leftside-authform",
                        "username-input"
                      )}
                      className={classes}
                      placeholder={t("Username")}
                      {...field}
                      disabled={isLoading}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>{t("Password")}</FormLabel>
                <FormControl>
                  <div className="relative w-full">
                    <Input
                      type="password"
                      className={classes}
                      id={getFQCN(
                        fqcn_bui,
                        "leftside-authform",
                        "password-input"
                      )}
                      placeholder={t("Password")}
                      {...field}
                      disabled={isLoading}
                      fqcn_bui={fqcn_bui}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    id={getFQCN(fqcn_bui, "leftside-authform", "login-button")}
                    name="logIn-button"
                    type="submit"
                    variant="blue"
                    className="w-full text-xl"
                    disabled={isLoading}
                    isLoading={isLoading}
                    fqcn_bui={fqcn_bui}
                    iconEnd={
                      <HiLogout
                        id={getFQCN(fqcn_bui, "login-icon")}
                        className="h-6 w-6"
                      />
                    }
                  >
                    {t("Log in")}
                  </Button>
                </TooltipTrigger>

                <TooltipContent>
                  <p>{t("Log in")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          <p className="text-red-500">{errorMsg}</p>
        </form>
      </Form>
    </>
  );
}
