import * as React from "react";

// React Icons
import { TbEye, TbEyeOff } from "react-icons/tb";

// Components
import { FormLabel } from "../Form";

// Utils
import { cn } from "@/utils/functions/cn";
import { getFQCN } from "@/utils/functions/fqcn";
import { IFQCN_BUI } from "@/utils/types/common";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  fqcn_bui?: IFQCN_BUI;
}

const default_fqcn = {
  Bundle: "defaultInputBundle",
  Unit: "input",
  Interface: "input",
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, fqcn_bui = default_fqcn, label, ...props }, ref) => {
    const [tempType, setTempType] = React.useState(type);

    const iconClass =
      "absolute right-0 top-0 m-2.5 h-5 w-5 cursor-pointer transition-all duration-700 ease-in-out";
    return (
      <div className="flex flex-col gap-2">
        {label && <FormLabel>{label}</FormLabel>}
        <input
          type={tempType}
          className={cn(
            "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            className
          )}
          ref={ref}
          {...props}
        />

        {type === "password" && (
          <>
            {tempType === "password" ? (
              <TbEye
                id={getFQCN(fqcn_bui, "password-login-icon")}
                className={iconClass}
                onClick={() => setTempType("text")}
              />
            ) : (
              <TbEyeOff
                id={getFQCN(fqcn_bui, "password-login-icon")}
                className={iconClass}
                onClick={() => setTempType("password")}
              />
            )}
          </>
        )}
      </div>
    );
  }
);

Input.displayName = "Input";

export { Input };
